/**
 * Logged In Wrapper Component
 * 
 * Makes sure that the user is logged in before seeing any of the
 * component's children. Also handles checking for if the team captain
 * has changed, or if the user needs to be automatically logged out due the game
 * being over.
 */

import React from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn, getCurrentUser } from '../utils/auth'
import { getCurrentTeamData, updateTeam, getHuntStatus, isTeamCaptain, isPermanentCode } from '../utils/team-data'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'

class LoggedInWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      original_captain: ``,
      alertShow: false,
      alertMsg: `Alert!`,
      logout_time: false,
    }
    this.checkLogout = this.checkLogout.bind(this)
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      console.log(`not logged in, navigating to login`)
      navigate(`/login`)
      return false
    }
    if (!isPermanentCode()) {
      this.interval = setInterval(() => this.update(), 15 * 1000) // update every 15 seconds
    }
    this.checkLogout()
    this.logout_interval = setInterval(() => this.checkLogout(), 5 * 60 * 1000) // check if logout every 5 minutes
    let teamData = getCurrentTeamData()
    this.setState({ original_captain: teamData.leader })
    //this.checkLogout()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.logout_interval)
  }

  checkLogout() {
    //console.log(`checking if need to log out...`)
    let now = new Date().getTime()
    let logout_time = this.state.logout_time
    if (!logout_time) {
      let huntData = getCurrentHuntData()
      let huntEnd = huntData.hunt !== undefined && huntData.hunt.end ? huntData.hunt.end : false
      //console.log(huntEnd)
      if (huntEnd) {
        let logout_time = Date.parse(huntEnd)
        this.setState({ logout_time: logout_time })
      }
    }
    if (logout_time && now > logout_time) {
      // do an automatic logout
      console.log(`automatically logging out...`)
      navigate(`/logout`)
    }
  }

  update() {
    updateTeam().then(() => {
      const status = getHuntStatus()
      if (status == `ended`) {
        // show end card
        console.log(`hunt has ended`)
        if (!isTeamCaptain()) {
          clearInterval(this.interval)
          if(this.showFinish){
            this.setShowFinish(false)
            navigate(`/finish/`)
          }
        }
      }
      let teamData = getCurrentTeamData()
      // Alert user if they are no longer the team captain
      if (teamData !== {}) {
        if (this.state.original_captain != teamData.leader && this.state.original_captain == getCurrentUser().guid) {
          console.log(`Team captain has changed`)
          this.setState({
            original_captain: teamData.leader,
          })
          this.showAlert(`You are no longer the team captain.`)
        }
      }
    })
  }

  render() {
    const { children } = this.props

    return (
      <AppContext.Consumer>
        {({ showAlert, showFinish, setShowFinish }) => {
          this.showAlert = showAlert
          this.showFinish = showFinish
          this.setShowFinish = setShowFinish
          return <>{children}</>
        }}
      </AppContext.Consumer>
    )
  }
}

export default LoggedInWrapper
