/**
 * Contact the Host Page
 * 
 * Shows host contact info for when a team needs help
 */

import React, { useContext, useState, useEffect } from 'react'

import LoggedInWrapper from '../components/loggedinWrapper'
import AppContext from '../utils/context'
import SvgArrow from '../components/svg/arrow'
import Link from '../components/link'
import { getCurrentHuntData } from '../utils/hunt-data'

const ContactPage = () => {
  const context = useContext(AppContext)
  const [data, setData] = useState({})

  useEffect(() => {
    setData(getCurrentHuntData())
    context.setLoading(false)
  }, [])

  return (
    <LoggedInWrapper>
      {data.hunt !== undefined && (
        <>
          <div className="site-sub-header px-4 py-2">
            <div className="row">
              <div className="col-2 d-flex align-items-center">
                <Link to={`/`} className={`back-arrow`}>
                  <SvgArrow />
                </Link>
              </div>
              <div className="col-10 text-right">
                <h1 className={`title h5 smaller text-uppercase mb-0 d-inline`}>
                  {data.hunt.contact_page_title
                    ? data.hunt.contact_page_title
                    : `Contact Host`}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            <div className={`card mx-4`}>
              <div className="card-header text-center">
                <h3>
                  {data.hunt.contact_page_card_heading
                    ? data.hunt.contact_page_card_heading
                    : `Having Trouble?`}
                </h3>
              </div>
              <div className="card-body pb-3">
                <div className="card-text" dangerouslySetInnerHTML={{ __html: data.hunt.contact_page_text }} />
                {data.hunt.host_contact_info.phone && <div className="card-text"><p><b>{data.hunt.host_contact_info.name}</b><br/><a href={`tel:${data.hunt.host_contact_info.phone}`}>{data.hunt.host_contact_info.phone}</a></p></div>}
              </div>
            </div>
          </div>
        </>
      )}
    </LoggedInWrapper>
  )
}

export default ContactPage